import * as _ from 'lodash';

export function searchFromStart(options, query) {
  return options.filter((el) => _.startsWith(_.toLower(el.text), _.toLower(query)));
}

export function sleep(ms: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function getFileExtension(fname: string): string {
  return fname.slice(((fname.lastIndexOf('.') - 1) >>> 0) + 2).toLowerCase();
}

export function isImage(fname: string): boolean {
  const ext = getFileExtension(fname);

  if (['png', 'jpg', 'jpeg'].includes(ext)) {
    return true;
  }

  return false;
}

export function isVideo(fname: string): boolean {
  const ext = getFileExtension(fname);

  if (['mp4'].includes(ext)) {
    return true;
  }

  return false;
}
