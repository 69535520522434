import React, { Component } from 'react';

export const titleBlue = "#324483";
export const dividerBlue = "#DEEEFF";
export const primaryBlue = "#3179FF";
export const subtitleGrey = "#717480";
export const cardsTextDark = "#171725";
export const cardsTextGrey = "#585D72";

const SharedStyle = {
    innerContent: {
        marginTop: '55px',
        marginLeft: '50px', 
    },
    contentCardDefault: {
        alignItems:"center",
        border: '2px solid #585D724D',
        borderRadius:'8px',
        cursor: "pointer",
    },
    contentCardSelected: {
        alignItems:"center",
        border: '2px solid #3179FF',
        borderRadius:'8px',
        cursor: "pointer",
    }
};

export default SharedStyle;