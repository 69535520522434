export enum ReportingCategory {
    Crashes = 'Crashes',
    Errors = 'Errors',
    FlowDeadEnds = 'FlowDeadEnds',
    CutTextOrGraphic = 'CutTextOrGraphic',
    NotFunctionCorrectly = 'NotFunctionCorrectly',
    BrokenLinks = 'BrokenLinks',
    LongLoadingTimes = 'LongLoadingTimes',    
    BatteryDrain = 'BatteryDrain',
  }
  