import { OperationStatus } from '../../types/OperationStatus';

export default (state = null, action) => {
  switch (action.type) {
    case 'LOGIN_START':
      return {
        status: OperationStatus.processing,
        errorMessage: '',
      };
    case 'LOGIN_SUCCESS':
      return {
        status: OperationStatus.success,
        errorMessage: '',
      };
    case 'LOGIN_FAIL':
      return {
        status: OperationStatus.fail,
        errorMessage: action.errorMessage,
      };
    case 'LOGIN_MODAL_CLOSE':
      return {
        status: OperationStatus.notStarted,
        errorMessage: '',
      };
    case 'LOGOUT':
      return {
        status: OperationStatus.notStarted,
        errorMessage: '',
      };
    default:
      return {
        status: OperationStatus.notStarted,
        errorMessage: '',
      };
  }
};
