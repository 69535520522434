export enum PlatformType {
    iPhone = 'iPhone',
    iPad = 'iPad',
    Mac = 'Mac',
    iWatch = 'iWatch',
    AndroidMobile = 'Android mobile',
    AndroidTablet = 'Android tablet',
    AndroidSmartwatch = 'Android smartwatch',
    WindowsDesktop = 'Windows desktop',
    WindowsPhone = 'Windows phone',
}

export const PlatformTypes = [
    {
        text: 'iPhone',
        key: PlatformType.iPhone
    },
    {
        text: 'iPad',
        key: PlatformType.iPad
    },
    {
        text: 'Mac',
        key: PlatformType.Mac
    },
    {
        text: 'iWatch',
        key: PlatformType.iWatch
    },
    {
        text: 'Android mobile',
        key: PlatformType.AndroidMobile
    },
    {
        text: 'Android tablet',
        key: PlatformType.AndroidTablet
    },
    {
        text: 'Windows desktop',
        key: PlatformType.WindowsDesktop
    },
    {
        text: 'Windows phone',
        key: PlatformType.WindowsPhone
    },          
  ];

  export class baseObject {
    id: number;
    name: string;

    constructor() {
        this.id = 0;
        this.name = '';
    }
  }

  //For main platform
  export interface PlatformBaseObject extends baseObject {
    minOSVersion?: baseObject;
    maxOSVersion?: baseObject;    
  }

  //For browser
  export interface BrowserBaseObject extends baseObject {
    minBrowserVersion: number;
    maxBrowserVersion: number;    
  }


  export class Country {
    key: string;
    value: string; 
    text: string;

    constructor(){
        this.key = '';
        this.value = '';
        this.text = '';
    }
  }

  //For Device
  export class DeviceTestObject {
    id: number;
    device: baseObject;
    country?: string;
    browser?: baseObject;
    numberOfTesters: number;
    
    constructor( index: number ) {
        this.id = index;
        this.device = new baseObject();
        this.device.name = 'All';
        this.country = 'All countries';
        this.browser = new baseObject();
        this.browser.name = 'Relevant browsers'
        this.numberOfTesters = 5;
  }
}
  
  export type OS_Version = {
    id: number;
    platformId: PlatformType;
    version: string;
  }

  export type Browser_Version = {
    id: number;
    platformId: PlatformType;
    name: string;
    version: string;
  }



export const MOCPlatforms = [
    {
        "id" : 1, 
        "name": "iPhone", 
        "OSVersions":[         
            {
                "id": 11, 
                "name": "iOS 14.0"
            },
            {
                "id": 12,
                "name": "iOS 14.1"
            },
        ],
        "Devices":[
            {
                "id": 1, 
                "name": "iPhone6"
            },
            {
                "id": 2, 
                "name": "iPhone6 Plus"
            },
            {
                "id": 3, 
                "name": "iPhone8"
            },
            {
                "id": 4, 
                "name": "iPhoneX"
            },
        ],
        "browsers" : [
            {
                "id" : 2,
                "name" : "Safari", 
                "versionsAvailable":[
                    {
                        "id": 21, 
                        "name": "Safari 12"
                    },
                    {
                        "id": 22, 
                        "name": "Safari 13"
                    },
                    {
                        "id": 23, 
                        "name": "Safari 13"
                    },                    
                ]
            },
            {
                "id" : 3,
                "name" : "Chrome", 
                "versionsAvailable":[
                    {
                        "id": 31, 
                        "name": "87.0.42"
                    },
                    {
                        "id": 32, 
                        "name": "88.0.43"
                    },{
                        "id": 33, 
                        "name": "89.0.42"
                    },
                    {
                        "id": 35, 
                        "name": "90.0.43"
                    },      
                ]
            },            
        ]
    },
    
    {
        "id" : 4, 
        "name": "Android mobile", 
        "OSVersions":[         
            {
                "id": 41, 
                "name": "Android 5 Lollipop"
            },
            {
                "id": 42,
                "name": "Android 8 Oreo"
            },
            {
                "id": 43,
                "name": "Android 9 Pie"
            },
        ],
        "Devices":[
            {
                "id": 5, 
                "name": "Nexus 9"
            },
            {
                "id": 6, 
                "name": "Galaxy S3"
            },
            {
                "id": 7, 
                "name": "Xperia"
            },            
        ],

        "browsers" : [            
            {
                "id" : 5,
                "name" : "Chrome", 
                "versionsAvailable":[
                    {
                        "id": 51, 
                        "name": "89.0.4389"
                    },
                    {
                        "id": 52, 
                        "name": "90.0.441"
                    },      
                ]
            },            
        ]
    },

    {
        "id" : 6, 
        "name": "Windows PC", 
        "OSVersions":[         
            {
                "id": 61, 
                "name": "Windows 8.1"
            },
            {
                "id": 62,
                "name": "Windows 10"
            },
        ],
        "Devices":[
            {
                "id": 8, 
                "name": "PC"
            },            
        ],

        "browsers" : [            
            {
                "id" : 7,
                "name" : "Chrome", 
                "versionsAvailable":[
                    {
                        "id": 71, 
                        "name": "89.0.4389"
                    },
                    {
                        "id": 72, 
                        "name": "90.0.441"
                    },      
                ]
            },
            {
                "id" : 8,
                "name" : "Internet Explorer", 
                "versionsAvailable":[
                    {
                        "id": 81, 
                        "name": "9"
                    },
                    {
                        "id": 82, 
                        "name": "10"
                    },      
                ]
            },
            {
                "id" : 9,
                "name" : "Edge", 
                "versionsAvailable":[
                    {
                        "id": 91, 
                        "name": "90"
                    },
                    {
                        "id": 92, 
                        "name": "91"
                    },      
                ]
            },
        ]
    },
];

