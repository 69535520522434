import React from 'react';
import { Link } from 'react-router-dom';
import previewImg from '../public/images/aboutus.jpg';

const ArticlePreview = (props) => {
  return (
    <div className="article-preview">
      <img src={props.image} alt="" />
      <div>
        <h2>{props.title}</h2>
        <span className="publish-date">{props.publishDate}</span>
        <p className="teaser">
          {props.teaser} <Link to={props.link}>read it</Link>
        </p>
      </div>
    </div>
  );
};

const Articles = (props) => {
  return (
    <div className="articles page">
      <div className="heading">
        <h1>Articles</h1>
      </div>
      <div className="inner">
        <ArticlePreview
          title="Mobile automation testing"
          publishDate="07-08-2018"
          teaser="Creating mobile apps is a complex process. Although many of us are convinced that all it takes is a good idea, in reality the way is much more complicated than that. In order to see the idea turned into reality perfectly (taking in account of everything that has changed in between) – a lot of patience is needed, as well as the ability to stick to t ... "
          image={previewImg}
          link="articles/single"
        />

        <ArticlePreview
          title="Mobile testing tools"
          publishDate="07-08-2018"
          teaser="Creating mobile apps is a complex process. Although many of us are convinced that all it takes is a good idea, in reality the way is much more complicated than that. In order to see the idea turned into reality perfectly (taking in account of everything that has changed in between) – a lot of patience is needed, as well as the ability to stick to t ... "
          image={previewImg}
          link="articles/single"
        />

        <ArticlePreview
          title="Mobile automation testing"
          publishDate="07-08-2018"
          teaser="Creating mobile apps is a complex process. Although many of us are convinced that all it takes is a good idea, in reality the way is much more complicated than that. In order to see the idea turned into reality perfectly (taking in account of everything that has changed in between) – a lot of patience is needed, as well as the ability to stick to t ... "
          image={previewImg}
          link="articles/single"
        />

        <ArticlePreview
          title="Mobile automation testing"
          publishDate="07-08-2018"
          teaser="Creating mobile apps is a complex process. Although many of us are convinced that all it takes is a good idea, in reality the way is much more complicated than that. In order to see the idea turned into reality perfectly (taking in account of everything that has changed in between) – a lot of patience is needed, as well as the ability to stick to t ... "
          image={previewImg}
          link="articles/single"
        />
      </div>
    </div>
  );
};

export default Articles;
