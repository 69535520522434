import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Switch } from 'react-router';
import { Route } from 'react-router-dom';
import PrivateRoute from './util/PrivateRoute';
import { Dimmer, Loader, Modal } from 'semantic-ui-react';
import { ToastContainer } from 'react-toastify';

import Header from './components/Header';
import Home from './components/Home';
import RegisterConfirm from './components/RegisterConfirm';
import ResetPassword from './components/ResetPassword';
import SampleReports from './components/SampleReports';

import Cycles from './components/Cycles/Cycles';
import CycleCreationResult from './components/Cycles/CycleCreationResult';

import NewCycleWizard from './components/Wizard/NewCycleWizard';

import Impersonate from './components/Impersonate';
import RedirectLogin from './components/RedirectLogin';

import Terms from './components/Terms';
import Privacy from './components/Privacy';
import Articles from './components/Articles';
import Article from './components/Article';
import About from './components/About';
import Contact from './components/Contact';
import BecomeATester from './components/BecomeATester';
import PageNotFound from './components/PageNotFound';

import EmailPending from './components/EmailPending';
import CompleteProfile from './components/CompleteProfile';
import Account from './components/Account';
import AccountTester from './components/Account/Tester/AccountTester';
import AccountTesterProfile from './components/Account/Tester/MyProfile/MyProfile';

import AccountTesterCycleDetails from './components/Account/Tester/Cycle/Details';
import AccountTesterCycleTestPlan from './components/Account/Tester/Cycle/TesterTestPlan';
import AccountTesterCycleApply from './components/Account/Tester/Cycle/Apply';
import AccountTesterCycleNewBug from './components/Account/Tester/Cycle/NewBug';
import AccountTesterCycleBugs from './components/Account/Tester/Cycle/Bugs';
import AccountTesterCycleEditBug from './components/Account/Tester/Cycle/EditBug';
import AccountTesterCycleMessages from './components/Account/Tester/Cycle/Messages';

import AccountTesterReviews from './components/Account/Tester/CustomerReviews';
import AccountTesterHDIW from './components/Account/Tester/HowDoesItWork';

import AccountCustomer from './components/Account/Customer/AccountCustomer';
import AccountCustomerProfile from './components/Account/Customer/MyProfile/MyProfile';
import AccountCustomerFavorite from './components/Account/Customer/FavoriteTesters';
import AccountCustomerFavoriteDetails from './components/Account/Customer/FavoriteTesterDetails';
import AccountCustomerCycleDetails from './components/Account/Customer/Cycle/Details';
import AccountCustomerCycleTestPlan from './components/Account/Customer/Cycle/CustomerTestPlan';
import AccountCustomerCycleMessages from './components/Account/Customer/Cycle/Messages';
import AccountCustomerCycleQuestions from './components/Account/Customer/Cycle/Questions';
import AccountCustomerCycleTesters from './components/Account/Customer/Cycle/Testers';
import AccountCustomerCycleTesterDetails from './components/Account/Customer/Cycle/TesterDetails';
import AccountCustomerCycleReportedBugs from './components/Account/Customer/Cycle/ReportedBugs';
import AccountCustomerHDIW from './components/Account/Customer/HowDoesItWork';

import Footer from './components/Footer';

import { OperationStatus } from './types/OperationStatus';
import actions from './redux/actions';
import RedirectModal from './components/RedirectModal';
import RedirectModalLauncher from './components/RedirectModalLauncher';

console.log(`WETEST APP V${process.env.REACT_APP_VERSION}`);

// TODO too many imports, split
// TODO check if a JWT token present and valid. if it's expired, delete it
// TODO move routes to a separate file

const mapStateToProps = (state: any) => {
  return {
    loginStatus: state.loginStatus.status,
    impersonateStatus: state.impersonateStatus.status,
    user: state.user,
    devices: state.devices,
    browsers: state.browsers,
    redirectModalOpen: state.redirectModalOpen,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getDevices: () => dispatch(actions.getDevices()),
    getBrowsers: () => dispatch(actions.getBrowsers()),
    checkLogin: () => dispatch(actions.checkLogin()),
    promptRedirect: () => dispatch(actions.propmtRedirect()),
    closeRedirect: () => dispatch(actions.closeRedirect())
  };
};

class App extends Component<any> {  

  componentDidMount() {
    //console.log('this.props.location.pathname', this.props.location.pathname);
    this.props.getDevices();
    this.props.getBrowsers();
    //this.props.checkLogin();
  }

  goToTestpoint = () => {
    window.location.href = "https://testpoint.com";
  }

  render() {
    const loaderActive = 
      !this.props.devices.models.length ||
      !this.props.browsers.names.length ||
      this.props.impersonateStatus === OperationStatus.processing;
      
    return (
      <div className="app">
        <ToastContainer
          position="top-right"
          autoClose={3000}
          newestOnTop={false}
          rtl={false}
          draggable={false}
          closeOnClick
          pauseOnHover
          hideProgressBar
        />
        <RedirectModal
          open={this.props.redirectModalOpen}
          onClose={this.props.closeRedirect}
          onRedirect={this.goToTestpoint}
        >
          
        </RedirectModal>
        <Header />
        <main>
          {loaderActive ? (
            <Dimmer active={loaderActive} page>
              <Loader></Loader>
            </Dimmer>
          ) : (
            <>              
            <Switch>
              <Route path="/" exact component={Home} />
              <Route path="/contact" component={Contact} />
              <Route path="/terms" component={Terms} />
              <Route path="/privacy" component={Privacy} />
              <Route path="/articles" exact component={Articles} />
              <Route path="/articles/single" component={Article} />
              <Route path="/about" component={About} />              
              

              {/* <Route path="/registerconfirm" component={RegisterConfirm} />
              <Route path="/reset-password" component={ResetPassword} />
              <Route path="/impersonate/:token" component={Impersonate} />
              <Route path="/redirect-login" component={RedirectLogin} />

              <Route path="/samplereports" component={SampleReports} />
              <Route path="/becomeatester" component={BecomeATester} />
              <Route path="/cycles" component={Cycles} />
              <Route path="/cycle-creation-result" component={CycleCreationResult} />
              <Route path="/wizard" component={NewCycleWizard} />
              <Route path="/terms" component={Terms} />
              <Route path="/privacy" component={Privacy} />
              <Route path="/articles/single" component={Article} />
              <Route path="/articles" exact component={Articles} />
              <Route path="/about" component={About} />              

              <PrivateRoute
                path="/email-pending"
                component={EmailPending}
                status={this.props.user.status}
                role={this.props.user.role}
              />
              <PrivateRoute
                path="/complete-profile/:step"
                component={CompleteProfile}
                status={this.props.user.status}
                role={this.props.user.role}
              />
              <PrivateRoute
                path="/account"
                exact
                component={Account}
                status={this.props.user.status}
                role={this.props.user.role}
              />
              <PrivateRoute
                path="/account/tester"
                exact
                component={AccountTester}
                status={this.props.user.status}
                role={this.props.user.role}
              />
              <PrivateRoute
                path="/account/tester/myprofile/:tab?"
                component={AccountTesterProfile}
                status={this.props.user.status}
                role={this.props.user.role}
              />
              <PrivateRoute
                path="/account/tester/reviews"
                component={AccountTesterReviews}
                status={this.props.user.status}
                role={this.props.user.role}
              />
              <PrivateRoute
                path="/account/tester/howdoesitwork"
                component={AccountTesterHDIW}
                status={this.props.user.status}
                role={this.props.user.role}
              />
              <PrivateRoute
                path="/account/tester/cycle/:id"
                exact
                component={AccountTesterCycleDetails}
                status={this.props.user.status}
                role={this.props.user.role}
              />
              <PrivateRoute
                path="/account/tester/cycle/:id/apply"
                component={AccountTesterCycleApply}
                status={this.props.user.status}
                role={this.props.user.role}
              />
              <PrivateRoute
                path="/account/tester/cycle/:id/testplan"
                component={AccountTesterCycleTestPlan}
                status={this.props.user.status}
                role={this.props.user.role}
              />
              <PrivateRoute
                path="/account/tester/cycle/:id/newbug"
                component={AccountTesterCycleNewBug}
                status={this.props.user.status}
                role={this.props.user.role}
              />
              <PrivateRoute
                exact
                path="/account/tester/cycle/:id/bugs"
                component={AccountTesterCycleBugs}
                status={this.props.user.status}
                role={this.props.user.role}
              />
              <PrivateRoute
                path="/account/tester/cycle/:cycleId/bugs/:bugId/edit"
                component={AccountTesterCycleEditBug}
                status={this.props.user.status}
                role={this.props.user.role}
              />
              <PrivateRoute
                path="/account/tester/cycle/:id/messages"
                component={AccountTesterCycleMessages}
                status={this.props.user.status}
                role={this.props.user.role}
              />

              <PrivateRoute
                path="/account/customer/myprofile"
                component={AccountCustomerProfile}
                status={this.props.user.status}
                role={this.props.user.role}
              />
              <PrivateRoute
                path="/account/customer/favorite"
                exact
                component={AccountCustomerFavorite}
                status={this.props.user.status}
                role={this.props.user.role}
              />
              <PrivateRoute
                path="/account/customer/favorite/:testerId"
                component={AccountCustomerFavoriteDetails}
                status={this.props.user.status}
                role={this.props.user.role}
              />
              <PrivateRoute
                path="/account/customer/howdoesitwork"
                component={AccountCustomerHDIW}
                status={this.props.user.status}
                role={this.props.user.role}
              />
              <PrivateRoute
                path="/account/customer/cycle/:id"
                exact
                component={AccountCustomerCycleDetails}
                status={this.props.user.status}
                role={this.props.user.role}
              />
              <PrivateRoute
                path="/account/customer/cycle/:id/testplan"
                component={AccountCustomerCycleTestPlan}
                status={this.props.user.status}
                role={this.props.user.role}
              />
              <PrivateRoute
                path="/account/customer/cycle/:id/messages"
                component={AccountCustomerCycleMessages}
                status={this.props.user.status}
                role={this.props.user.role}
              />
              <PrivateRoute
                path="/account/customer/cycle/:id/questions"
                component={AccountCustomerCycleQuestions}
                status={this.props.user.status}
                role={this.props.user.role}
              />
              <PrivateRoute
                path="/account/customer/cycle/:id/testers"
                exact
                component={AccountCustomerCycleTesters}
                status={this.props.user.status}
                role={this.props.user.role}
              />
              <PrivateRoute
                path="/account/customer/cycle/:id/testers/:testerId"
                component={AccountCustomerCycleTesterDetails}
                status={this.props.user.status}
                role={this.props.user.role}
              />
              <PrivateRoute
                path="/account/customer/cycle/:id/report"
                component={AccountCustomerCycleReportedBugs}
                status={this.props.user.status}
                role={this.props.user.role}
              />
              <PrivateRoute
                path="/account/customer"
                exact
                component={AccountCustomer}
                status={this.props.user.status}
                role={this.props.user.role}
              />
              */}
              <Route component={RedirectModalLauncher} />
              
            </Switch>
            </>
          )}
        </main>
        <Footer />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
