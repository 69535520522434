import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, Form, Message, Input, Icon, Button, Divider } from 'semantic-ui-react';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import actions from '../redux/actions';
import { OperationStatus } from '../types/OperationStatus';
import { UserRole } from '../types/UserRole';
import PasswordResetModal from './PasswordResetModal';
import PasswordFBModal from './PasswordFBModal';
import config from '../config';

const mapStateToProps = (store) => {
  return {
    modalOpen: store.loginModal.open,
    userRole: store.user.role,
    loginStatus: store.loginStatus.status,
    loginErrorMessage: store.loginStatus.errorMessage,
    sendPasswordResetEmailStatus: store.sendPasswordResetEmailStatus.status,
    sendPasswordResetEmailErrorMessage: store.sendPasswordResetEmailStatus.errorMessage,

    sendPasswordFBEmailStatus: store.sendPasswordFBEmailStatus.status,
    sendPasswordFBEmailErrorMessage: store.sendPasswordFBEmailStatus.errorMessage,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    login: (email, password) => dispatch(actions.login(email, password)),
    loginFacebook: (email, accessToken, facebookbUserId, avatarUrl) =>
      dispatch(actions.loginFacebook(email, accessToken, facebookbUserId, avatarUrl)),
    open: () => dispatch(actions.loginModalOpen()),
    close: () => dispatch(actions.loginModalClose()),
    sendPasswordResetEmail: (email) => dispatch(actions.sendPasswordResetEmail(email)),
    sendPasswordFBEmail: (email) => dispatch(actions.sendPasswordFBEmail(email)),
    refreshPasswordResetStatus: () => dispatch(actions.refreshPasswordResetStatus()),
  };
};

const initialState = {
  email: '',
  password: '',
  passwordVisible: false,
  passwordResetModal: {
    open: false,
    email: '',
  },
  passwordFBModal: {
    open: false,
    email: '',
  },
};

class LoginModal extends Component<any> {
  public state: any;

  constructor(props) {
    super(props);
    this.state = initialState;
  }

  onOpen = (event) => {
    event.preventDefault();
    this.props.open();
  };

  onClose = (event) => {
    event.preventDefault();
    this.setState(initialState);
    localStorage.removeItem('redirectAfterLogin');
    this.props.close();
  };

  onInput = (event, data) => {
    event.preventDefault();
    event.stopPropagation();

    this.setState({
      ...this.state,
      [data.name]: data.value,
    });
  };

  onPasswordVisibleToggle = (event) => {
    event.preventDefault();
    this.setState({ passwordVisible: !this.state.passwordVisible });
  };

  onSubmit = async () => {
    if (
      this.props.loginStatus === OperationStatus.processing ||
      !this.state.email ||
      !this.state.password
    ) {
      return;
    }

    this.props.login(this.state.email, this.state.password);
  };

  onPasswordModalOpen = () => {
    this.setState({
      ...this.state,
      passwordResetModal: {
        open: true,
      },
    });
  };

  onPasswordFBModalOpen = () => {
    this.setState({
      ...this.state,
      passwordFBModal: {
        open: true,
      },
    });
  };

  onPasswordModalClose = () => {
    if (this.props.sendPasswordResetEmailStatus === OperationStatus.success) {
      return this.setState(
        {
          ...this.state,
          open: false,
          passwordResetModal: {
            open: false,
          },
        },
        () => {
          this.props.refreshPasswordResetStatus();
        }
      );
    }

    this.setState({
      ...this.state,
      passwordResetModal: {
        open: false,
      },
    });
  };

  onPasswordFBModalClose = () => {
    if (this.props.sendPasswordFBEmailStatus === OperationStatus.success) {
      return this.setState(
        {
          ...this.state,
          open: false,
          passwordFBModal: {
            open: false,
          },
        },
        () => {
          this.props.refreshPasswordResetStatus();
        }
      );
    }

    this.setState({
      ...this.state,
      passwordFBModal: {
        open: false,
      },
    });
  };

  onPasswordModalInput = (event, data) => {
    this.setState({
      ...this.state,
      passwordResetModal: {
        ...this.state.passwordResetModal,
        email: data.value,
      },
    });
  };

  onPasswordFBModalInput = (event, data) => {
    this.setState({
      ...this.state,
      passwordFBModal: {
        ...this.state.passwordFBModal,
        email: data.value,
      },
    });
  };

  onPasswordModalSubmit = () => {
    if (
      this.props.sendPasswordResetEmailStatus === OperationStatus.processing ||
      !this.state.passwordResetModal.email
    ) {
      return;
    }

    this.props.sendPasswordResetEmail(this.state.passwordResetModal.email);
  };

  onPasswordFBModalSubmit = () => {
    console.log('onPasswordFBModalSubmit');
    if (
      this.props.sendPasswordFBEmailStatus === OperationStatus.processing ||
      !this.state.passwordFBModal.email
    ) {
      return;
    }

    this.props.sendPasswordFBEmail(this.state.passwordFBModal.email);
  };

  onFacebookLoginResponse = (response) => {
    console.log(response);

    if (this.props.userRole !== UserRole.Anonymous) {
      return;
    }

    let avatarUrl;

    if (response.picture?.data?.url && response.picture?.data?.is_silhouette !== true) {
      avatarUrl = response.picture.data.url;
    }

    this.props.loginFacebook(response.email, response.accessToken, response.userID, avatarUrl);
  };

  render() {
    return (
      <Modal
        open={this.props.modalOpen}
        onClose={this.onClose}
        className="login-modal"
        size="tiny"
        trigger={<a onClick={this.onOpen}>Login</a>}
        closeIcon
      >
        <Modal.Header>Login</Modal.Header>
        <Modal.Content>
          {this.props.loginErrorMessage && (
            <Message negative>
              <Icon name="exclamation circle" />
              {this.props.loginErrorMessage}
            </Message>
          )}

          <Form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <Form.Field>
              <Input
                icon="user"
                iconPosition="left"
                type="email"
                placeholder="Email"
                name="email"
                onChange={this.onInput}
                required
              />
            </Form.Field>
            <Form.Field>
              <Input
                icon="lock"
                iconPosition="left"
                type={this.state.passwordVisible ? 'text' : 'password'}
                placeholder="Password"
                name="password"
                onChange={this.onInput}
                required
                action={
                  <Button onClick={this.onPasswordVisibleToggle} type="button" tabIndex="-1" icon>
                    <Icon name={this.state.passwordVisible ? 'eye slash' : 'eye'} />
                  </Button>
                }
              />
            </Form.Field>
            <Form.Field>
              <PasswordResetModal
                open={this.state.passwordResetModal.open}
                onOpen={this.onPasswordModalOpen}
                onClose={this.onPasswordModalClose}
                onInput={this.onPasswordModalInput}
                onSubmit={this.onPasswordModalSubmit}
                errorMessage={this.props.sendPasswordResetEmailErrorMessage}
                status={this.props.sendPasswordResetEmailStatus}
              />
            </Form.Field>
            
            <Button
              icon
              primary
              type="submit"
              disabled={this.props.loginStatus === OperationStatus.processing}
              loading={this.props.loginStatus === OperationStatus.processing}
              onClick={this.onSubmit}
            >
              <Icon name="sign in" /> Login
            </Button>
          </Form>

          <Divider horizontal>Or</Divider>
          
          <FacebookLogin
            disableMobileRedirect={true}
            appId={config.facebookAppId}
            autoLoad={false}
            isMobile={false}
            callback={this.onFacebookLoginResponse}
            scope="public_profile, email"
            fields="name, email, picture"
            render={(renderProps) => (
              <Button
                icon
                color="facebook"
                disabled={this.props.loginStatus === OperationStatus.processing}
                loading={this.props.loginStatus === OperationStatus.processing}
                onClick={renderProps.onClick}
              >
                <Icon name="facebook f" /> Login with Facebook
              </Button>
            )}
          />
           
           {/**
          <Form.Field>
              <PasswordFBModal
                open={this.state.passwordFBModal.open}
                onOpen={this.onPasswordFBModalOpen}
                onClose={this.onPasswordFBModalClose}
                onInput={this.onPasswordFBModalInput}
                onSubmit={this.onPasswordFBModalSubmit}
                errorMessage={this.props.sendPasswordFBEmailErrorMessage}
                status={this.props.sendPasswordFBEmailStatus}
              />
            </Form.Field>
            */}
        </Modal.Content>
      </Modal>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginModal);
