
import React from 'react';
import { Modal, Button } from 'semantic-ui-react';
import { createStyles, makeStyles, } from '@material-ui/core/styles';
import {Typography} from '@material-ui/core';


const useStyles = makeStyles((theme) =>
    createStyles({

        root:{
            /* fontSize: '16px',
            color: '#324483',
            fontWeight: 'bolder',
            letterSpacing: '0.1px', */
        },                    
    
    }),
);

const RedirectModal = (props) => {
    const classes = useStyles();
    
    return(
        <Modal open={props.open} onClose={props.onClose} closeIcon>
            <Modal.Header>We-test testing platform is now Testpoint</Modal.Header>
                <Modal.Content className={classes.root}>
                    <Typography>
                        We've  migrated our testing platform to <strong>Testpoint</strong> as part of our focus strategy as a Testagency.
                    </Typography>
                    <Typography style={{marginTop: 15}}>
                        Aside from that, everything else stays the same, and you can continue using it like you always did.
                    </Typography>                                                
                    <Typography style={{marginTop: 15}}>
                        Your account was migrated in full, by clicking the 'Go' below you will be redirected to <i>Testpoint.com</i> where you can register or login with your existing credentials.
                    </Typography>                    
                    <Typography style={{marginTop: 15}}>
                        Customers, If you wish to get an offering from <i>We-test</i> test agency please approach via 'contact us'.
                    </Typography>
                </Modal.Content>

                <Modal.Actions>
                    <Button primary onClick={props.onRedirect}>
                        Go
                    </Button>
                </Modal.Actions>
        </Modal>
    );
};

export default RedirectModal;

 