import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import actions from '../redux/actions';

const mapStateToProps = (state: any) => {
  return {
    redirectModalOpen: state.redirectModalOpen,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    promptRedirect: () => dispatch(actions.propmtRedirect()),
  };
};

class RedirectModalLauncher extends Component<any> {
  public state: any;

  componentDidMount() {    
    this.props.promptRedirect();
  }

  componentDidUpdate(prevProps) {
    if (this.props.redirectModalOpen === false) {
      this.props.history.push('/');
    }
  }

  render() {
    return null;
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RedirectModalLauncher));
