const config = {
  evalCycleId: +(process.env.REACT_APP_EVEL_CYCLE_ID || 1),
  maxAttachmentsSizeMB: +(process.env.REACT_APP_MAX_ATTACHMENT_SIZE || 50),
  minimalCycleHourlyRate: +(process.env.REACT_APP_MINIMAL_CYCLE_HOURLY_RATE || 5),
  facebookAppId: process.env.REACT_APP_FACEBOOK_APP_ID || '',
  apiEndpoint: process.env.REACT_APP_API_LEGACY_ENDPOINT,
  apiNextEndpoint: process.env.REACT_APP_API_ENDPOINT,
};

export default config;
