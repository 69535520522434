import React from 'react';
import previewImg from '../public/images/aboutus.jpg';

const Article = () => {
  return (
    <div className="article page">
      <div className="heading">
        <h1>Mobile automation testing</h1>
      </div>
      <div className="inner">
        <img className="article-image" src={previewImg} alt="article" />

        <p>
          Getting mobile application planned, created and uploaded for the use of all mobile devices
          owners can be tricky and very long process. Sometimes things get out of schedule and there
          is very little time left for doing the applicable tests to determine that everything is
          working properly. However, skipping this step might be a big mistake with severe
          consequences for the future.
        </p>
        <h3>Utilizing mobile automation testing as a shortcut?</h3>
        <p>
          As a countermeasure, some people decide to go for mobile automation testing. It is
          definitely better than nothing, but usually not enough. It is easy to see why: when you
          have a real person checking your mobile application, they are going through the whole
          process any of your users would go through. Hence, there is a real investment and complete
          investigation of all the features in your project. Plus, the idea of testing holds the
          need to check the application on all the various devices, platforms and operating systems.
          The more testers that are looking into one specific application, the better it will
          eventually be.
        </p>
        <h3>Other reasons to invest into real people testing</h3>
        <p>
          There are plenty of advantages in giving your application the fullest attention from
          various users that as easily could have been real users of the project you are developing.
          Plus, having a professional testing company can give you the opportunity to outline your
          testing strategy with additional specifications that interest you. We-Test is the place to
          go for professional crowd-testing for making the final adjustment for your application for
          releasing it in the best shape possible.
        </p>
      </div>
    </div>
  );
};

export default Article;
