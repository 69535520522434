import React from 'react';

const Terms = (props) => {
  return (
    <div className="terms page">
      <div className="heading">
        <h1>Terms &amp; Conditions</h1>
      </div>
      <div className="inner">
        <strong>
          These Terms and Conditions («Terms») govern your use of our site, the testing services
          provided through our site and/or your participation as a tester on our site. You agree to
          be bound by these Terms by using our site and services and/or participating as a tester.
          If you do not agree to these Terms, do not use our site, services and/or register as a
          tester. If you are using our site or services on behalf of a company or any other
          organization, you represent that you have full power and authority to bind such company or
          organization to these Terms. By using our site and services and/or registering as a tester
          you represent that you are doing so lawfully, in compliance with any applicable law and
          without violating any agreement or right of any third party. Minors are prohibited from
          using our site and/or registering as testers.
        </strong>
        <h2>Confidentiality</h2>
        <p>
          Each party («Recipient») agrees to retain in confidence any information provided to it by
          the other party («Discloser») that is either marked as confidential or proprietary or that
          Recipient knew, or should have reasonably known, was confidential due to the circumstances
          surrounding the disclosure by Discloser («Confidential Information»). Confidential
          Information does not include any information that (a) is publicly known at the time of
          disclosure or thereafter becomes publically known through no fault of Recipient, (b) is
          received by Recipient from a third party without breach of any confidentiality
          undertakings, © is published or otherwise made known to the public by Discloser, (d) was
          generated independently without reference to the other party’s Confidential Information,
          or (e) is required to be disclosed under a court order or pursuant to any applicable law,
          provided that prior to such disclosure Recipient provided Discloser with prior written
          notice thereof, as permitted by law. Recipient undertakes to use Confidential Information
          only in relation to the performance of these Terms and not to disclose Confidential
          Information to any third party. Recipient shall not modify, reverse engineer, decompile,
          disassemble create other or derivative works from any Confidential Information. Recipient
          shall use at least the same standard of care with the Discloser’s Confidential Information
          as it does with its own Confidential Information, but in no event less than a reasonable
          degree of care. Upon termination or expiration of these Terms, or upon Discloser’s written
          request, Recipient shall return or destroy all Confidential Information provided under
          these Terms. Each party acknowledges that breach of these confidentiality provisions would
          result in irreparable harm to the other party, for which monetary damages would be an
          insufficient remedy, and therefore that the other party will be entitled to seek
          injunctive relief to enforce the provisions of these confidentiality provisions. All
          testers agree to terms that include these confidentiality provisions with regard to any
          testing services they provide. Testers will keep all Confidential Information received
          from customers and/or in regard to their tested applications and/or web- sites (including
          the existence of such applications and/or web-sites and or their engagement hereunder) in
          absolute confidence. Testers are prohibited from disclosing or using any Confidential
          Information other than for the strict purpose of provide the testing services hereunder.
          We will not be held liable for breach of confidentiality by customers and/or testers.
          These terms of confidentiality shall be deemed to have been concluded directly between
          customers and testers and/or they shall be deemed to be third party beneficiaries thereof,
          as required in order to assert their rights hereunder between themselves. These provisions
          of confidentiality shall survive for a period of five years after the expiration or
          termination of these Terms for whatever reason.
        </p>
        <h2>Communications Between Customers and Testers</h2>
        <p>
          Customers and Testers may only communicate with each other through our site and may not
          contact one another through any other means. Testers and customers are prohibited from
          providing any contact information or means other than that provided on our site. Customers
          may not solicit or hire any tester to perform testing services other than through our site
          for a period of one year from the completion of any testing services conducted on our site
          with that tester. Any such solicitation or hiring will be considered a material breach of
          these Terms and may result in the termination of these terms and your access to this site,
          our services and/or your participation as a tester.
        </p>
        <h2>Restrictions</h2>
        <p>
          You shall not submit, upload to, distribute through or otherwise post to the site any
          material that: (1) is libelous, defamatory, threatening, abusive, scandalous, obscene,
          pornographic or unlawful or that encourages a criminal offense; (2) contains any
          advertising, promotional, solicitation or other commercial material, including the uniform
          resource locator («URL») of a web- site, unless the web-site in question relates directly
          to a testing project on the site (notwithstanding the foregoing, you may post the URL of a
          web-site containing a portfolio of sample works); (3) contains material from other
          copyrighted works without the written consent of the owner of such copyrighted material,
          other than reasonable excerpts permitted under the copyright doctrine of fair use; (4)
          infringes any copyright, trademark or patent, incorporates any material that constitutes a
          trade secret of a third party, or violates any property rights, rights of privacy or
          publicity or any other rights of any third party; (5) contains any software viruses or any
          other code, file, program or malware that is designed to interrupt, destroy or limit the
          functionality of any computer software, hardware or telecommunications equipment or to
          damage, detrimentally interfere with, surreptitiously intercept or expropriate any system,
          data or personal information. We reserve the right (but are not obligated) to do any or
          all of the following: (1) investigate any allegation of violation of the foregoing
          restrictions; (2) remove any content that is deemed by us to be in violation of the
          foregoing restrictions; (3) monitor, edit or disclose any content posted on or through our
          site, regardless of whether such content violates any of these Terms. We and whomever on
          our behalf have no liability or responsibility to users of the site or any other person or
          entity for performance or non-performance of the aforementioned activities.
        </p>
        <h2>Ratings</h2>
        <p>
          Our site has developed and implements methodologies to rate tester’s performance and we
          allocate and offer test projects to testers according to such rating. Rating methodologies
          and their implementation and dissemination are in our sole discretion. Neither customers
          nor testers shall have any claim towards us with regard to a tester’s rating and/or the
          methods we use to determine a tester’s rating and/or the resulting offers of testing
          projects to testers anfd their allocation according to such rating and/or the quality of
          services that result therefrom.
        </p>
        <h2>Customer Terms</h2>
        <p>
          Our site offers customers access to our testing platform for mobile apps and web-sites. We
          currently offer two primary testing services — bug testing and user experience feedback.
          The tests are performed by testers who register to provide their services through our
          site. Testers are independent contractors and are not our employees or agents and we
          disclaim any liability for any of their acts or omissions. By making use of our site and
          services customers acknowledge and agree that we function as an intermediary, connecting
          customers with testers to evaluate and provide software testing services. We make no
          representations or warranties as to the quality of services provided by the testers. No
          guarantee is given that any or all test results (such as, but not limited to, bugs, test
          cases, test data, test scripts, and other test results, collectively — «Test Results») in
          connection with a customer’s software will be found, diagnosed, or resolved as a result of
          engaging testers through our site. WE DO NOT GUARANTEE THE PARTICIPATION OF A MINIMUM
          NUMBER TESTERS, REPORTED OR APPROVED TEST RESULTS, OR THE SERVICES PERFORMED BY THE
          TESTERS. WE WILL NOT BE HELD LIABLE FOR ANY DAMAGES ARISING OUT OF PENETRATION TESTS OR
          SIMULATED ATTACKS PERFORMED BY TESTERS AS PART OF ANY SECURITY OR VULNERABILITY TESTS.
          Customers may purchase testing services at a fixed fee per tester for the number of
          testers designated in the Order. These services need to be completed within the time
          period stated in the Order. Testers are allocated automatically by our platform according
          to their rating. If the testing services are not completed by the designated number of
          testers within the designated time period, the customer will be credited the difference in
          fees for future use of testing services. The fees applicable to a testing cycle shall be
          as set forth in an Order. An Order may contain further provisions that are applicable to
          that Order. Fees are due and payable immediately upon completion of the Order and
          designation of the testers allocated to execute the testing services ordered. In the event
          that you dispute an invoice, you must notify us within 15 days of the invoice date,
          otherwise such invoice shall be deemed undisputed. Overdue payments shall bear interest at
          1.5% per month or such lower rate as shall be the then highest rate permitted by law.
          Unpaid fees and shall be grounds to terminate customer’s access and use of our site and
          services without refund of any fees paid. All prices are exclusive of all taxes, duties,
          VAT, withholdings and other governmental assessments. You agree not to use our site to
          post false or misleading test project descriptions. We have sole discretion in determining
          the appropriateness of any content posted to or available through our site. You shall not
          falsify your identity or the identity of any third-party and you represent and warrant
          that your content will not include incomplete, misleading or inaccurate information. You
          agree and acknowledge that we may aggregate and share anonymous information with third
          parties, including data related to general testing time, standards, average number of bugs
          found and other Test Results reported, the number of bugs fixed, and additional aggregated
          statistical reports regarding customer’s applications that are being or have been tested.
          We will not specify any customer’s name or provide personal information relating to the
          customer (including e-mail addresses, accounts or financial information), but may mention
          the business areas or industries in which a customer operates and the type of applications
          or web-sites being tested. YOU HEREBY AGREE TO INDEMNIFY US AND WHOMEVER ON OUR BEHALF
          FROM AND AGAINST ANY COSTS, EXPENSES LIABILITIES AND FEES (INCLUDING LEGAL FEES) RELATING
          TO ANY CLAIMS WHICH RESULT FROM OR RELATE TO YOUR BREACH OF THESE TERMS.
        </p>
        <h2>Tester Terms</h2>
        <p>
          By registering as a tester, you acknowledge and agree that you are an independent
          contractor and not our employee. As an independent contractor, you are not entitled to
          participate in any employee benefits provided from time to time by us to our employees.
          You are responsible for providing and maintaining all computer equipment and internet
          connectivity necessary for the performance of your services as a tester. We are not
          obligated to provide you with any training, technical or administrative support or other
          assistance in connection with your duties as a tester. We do not guarantee that you will
          be assigned to any minimum number of testing projects. You may, in your sole discretion,
          elect to accept or decline to participate in any testing project which we offer you. In
          accepting a test project you will be informed of the deadline for the completion of the
          project and you will be responsible for determining when and how to perform the testing
          services in order to meet such deadline. In participating as a tester you will have access
          to new and developing software and information about customers to whom you will be
          providing testing services. You have an absolute duty of confidentiality regarding any and
          all applications, software and web-sites which you are testing, have tested, or will test,
          or any information you receive or learn of as a result of providing testing services
          through our site, all which are deemed «Confidential Information» as defined herein. This
          duty is owed both to us, the customers and any of their third-party suppliers, who shall
          be entitled to enforce this obligation of confidentiality as intended third party
          beneficiaries of these Terms for this purpose. You warrant and represent that you shall
          not, under any circumstances duplicate, use, distribute, copy or attempt to copy by any
          means, reverse engineer or attempt to reverse engineer, take screen shots or video
          captures of, or otherwise store any Confidential Information or disclose any Confidential
          Information to any third-parties whether in person, through any digital media, blogs, news
          sources, social networks or any other form of communication. Confidential Information is
          provided to you for the sole purpose of providing testing services and Testing Results to
          the customers and any other use is strictly prohibited. YOU HEREBY AGREE INDEMNIFY AND
          HOLD US AND WHOMEVER ON OUR BEHALF HARMLESS FROM ANY AND ALL LOSSES, CLAIMS, DAMAGES,
          FEES, EXPENSES OR OTHER LIABILITIES ARISING FROM OR RELATING TO YOUR VIOLATION OF THE
          FOREGOING CONFIDENTIALITY UNDERTAKINGS AND FURTHER ACKNOWLEDGE AND AGREE THAT YOU SHALL BE
          INDIVIDUALLY LIABLE TO ANY PERSON WHO’S INFORMATION YOU IMPROPERLY DISTRIBUTE. Your status
          as a tester does not preclude you from working as an independent contractor or employee of
          any other entity. You hereby represent and warrant that your provision of testing services
          hereunder will not violate any contract or agreement to which you are, or may become,
          bound, including, but not limited to, any employment agreement, services agreement,
          non-competition agreement or confidentiality agreement, whether or not reduced to writing.
          You agree not to solicit (other than by general advertisement), or hire, any of our
          employees or subcontractors (including, without limitation, any tester which performs
          testing services for us) during the term of your engagement hereunder and for a period of
          one year thereafter. PRIOR TO BEGINNING WORK ON ANY PARTICULAR TESTING PROJECT/ CYCLE, YOU
          SHALL VERIFY AND CERTIFY TO US THAT YOU DO NOT WORK FOR AND ARE NOT AFFILIATED WITH ANY
          COMPETITOR OF THE CUSTOMER FOR WHOM YOU WILL BE PROVIDING TESTING SERVICES. You shall not
          falsify your identity or the identity of any third-party and you represent and warrant
          that your content will not include incomplete, misleading or inaccurate information. You
          agree and acknowledge that we shall not be liable for any damage caused to any computer
          hardware or technology environments by use of our services or performance of testing
          services through our site. You acknowledge that your access to the site and your
          eligibility to participate in testing services as a tester is strictly conditioned upon
          your acceptance of such disclaimer of liability. We may, in our sole discretion from time
          to time, implement rules and procedures regarding disputes between testers and customers.
          You are responsible for paying your own state, local and other taxes on fees received from
          us and, unless we determine, in our sole discretion, that we have withholding obligations
          with respect to payments to be made to you, we shall not withhold any taxes and shall have
          no liability related thereto. You acknowledge that we may share your tester’s profile
          (excluding personal information), as completed by you with customers and other testers.
          You agree and acknowledge that we may aggregate and share anonymous information with third
          parties, including data related to general testing time, standards, average number of bugs
          found and other Test Results reported, the number of bugs fixed, and additional aggregated
          statistical reports regarding testers and their performance. YOU HEREBY AGREE TO INDEMNIFY
          US AND WHOMEVER ON OUR BEHALF FROM AND AGAINST ANY COSTS, EXPENSES LIABILITIES AND FEES
          (INCLUDING LEGAL FEES) RELATING TO ANY CLAIMS WHICH RESULT FROM OR RELATE TO YOUR BREACH
          OF THESE TERMS.
        </p>
        <p></p>

        <h2>Fees, Payments and Payment Terms</h2>
        <p>
          You shall be entitled to receive payment solely for completing and providing Test Results
          for the testing project in which you participate. Fees payable to testers may vary from
          time to time or project to project and may be changed by us at any time. The applicable
          fee payable with respect to each project shall be detailed in our project notice to you.
          Payments to testers shall be made to tester’s PayPal account. Payments through PayPal may
          be subject to certain fees to PayPal deducted from the payments made by us. Payments will
          be made monthly in arrears, and will be available for withdrawal by the 15th day of each
          calendar month for the preceding month. All amounts are stated in and all payments will be
          made in US Dollars. IT IS EACH TESTER’S RESPONSIBILITY TO REGISTER THE TESTER’S PAYPAL
          ACCOUNT WITH THE SITE. IF WITHIN THIRTY (30) DAYS AFTER THE END OF THE FIRST CALENDAR
          MONTH FOR WHICH A TESTER IS ENTITLED TO PAYMENT, THE TESTER HAS NOT REGISTERED THE
          TESTER’S PAYPAL ACCOUNT, THEN ANY RIGHT SUCH TESTER HAD TO RECEIVE SUCH AMOUNTS WILL BE
          AUTOMATICALLY FORFEITED. We reserve the right, at our sole discretion, to suspend or
          cancel any payment if: (1) the customer has disputed payment for the particular testing
          project; and/ or (2) the source of the funds is suspected to be fraudulent (e.g. using a
          stolen credit card). If payment has already been processed and is found to be either in
          dispute or fraudulent, you will be expected to return the funds paid or face termination
          of your participation as a tester on our site. We may set-off any amounts owed to us
          (including disputed or fraudulent payments) from any other payments or credit owed to you.
        </p>
        <ul>
          <li>
            Testers are responsible for paying any direct or indirect taxes, including any GST, VAT
            or otherwise, which may apply to them depending on residency, location or the billing
            information they provide in their account settings. Testers represent and warrant that
            they comply, and will comply at all times, with their obligations under income tax
            provisions in their jurisdiction.
          </li>
          <li>
            Appointment as Limited Payment Collection Agent: Tester hereby appoints We-test as
            Seller’s limited authorized payment collection agent solely for the purpose of accepting
            payments (via its Payment Services Provider, if applicable) from Buyer, and remitting
            those payments to Tester. Tester agrees that payment from Buyer to We-test shall be
            considered the same as a made directly to Tester. Buyer’s payment obligation to Tester
            will be satisfied upon receipt of payment by We-test (or its Payment Services Provider,
            as applicable), and We-test (via its Payment Services Provider, as applicable) is
            responsible for remitting the funds to the Tester in the manner described in these
            Payment Terms. In the event that We-test (via Payment Services Provider) does not remit
            any such amounts to Tester, the Tester will have recourse only against We-test and not
            the Buyer directly. Tester agrees that We-test may describe or otherwise reflect the
            terms contained herein in any terms of service, receipts, disclosures, or notices
            including, but not limited to, receipts provided to Buyers that We-test may deem
            necessary or prudent.
          </li>
        </ul>
        <h2>Term and Termination</h2>
        <p>
          These Terms will apply to your use of the site, its services, any orders for testing
          services («Order/s») and/or your participation as a tester. A party may provide written
          notice of termination. Upon notice of termination customers may not place any new Orders
          and testers may only complete testing services which they have committed to prior to
          termination. Orders made and testing services committed to prior to the notice of
          termination will continue in full force and effect until completed, unless we terminate
          your access to this site for material breach of these Terms. If you are in material breach
          of these terms, and this breach is incapable of remedy or if capable of remedy is not
          remedied promptly following written notice to you, we may terminate your access and youse
          of our site and services and/or your participation as a tester immediately and without
          further notice. The term «material breach» will include any act or omission which we
          reasonably deem to prejudice or harm us, our site, services, customers or testers. If a
          party defaults in the performance of any of its material obligations under any Order or
          these Terms, then the other party may terminate such Order by providing written notice of
          such termination (including, without limitation, a detailed description of the default and
          the action required to cure the default); provided, however, that such termination will
          not be effective if the defaulting party substantially cures the default within thirty
          (30) days after receipt of your notice of termination. Upon termination of any Order the
          customer will pay for access and use of the site and services performed in accordance with
          these Terms prior to the effective date of termination or thereafter as reasonably
          required to wind up the services under the terminated Order and we will deliver to you all
          completed testing results.
        </p>
        <h2>Intellectual Property</h2>
        <p>
          "Our IP" means any intellectual property rights, including patents, copyrights,
          trademarks, trade dress or other intellectual property owned, controlled, or licensed by
          us or on our behalf that are used by us in the performance of our obligations under these
          Terms — such as computer software, methodologies and web-site content (including all
          images, illustrations, icons, designs and written and other materials that appear on the
          site). Our IP also includes any elements of the services we provide, subject to your
          rights in your Confidential Information that are of general applicability to our business
          and provisions of services to other clients. Our IP is and will remain in our sole
          ownership and no right or license thereto is implied by these Terms. Our IP shall be
          deemed to be our Confidential Information. No Confidential Information obtained by us from
          you shall become our property under these Terms. You hereby grant us a temporary,
          non-exclusive license to your Confidential Information solely as needed to perform our
          obligations in connection with these Terms. We agree that to the extent that a Test Result
          is subject to copyright, every aspect thereof, other than Our IP that may be incorporated
          into such Test Result, is a «work made for hire» and the tester submitting such Test
          Result shall be considered the author of the Test Result for all purposes and the customer
          ordering such test result shall be the owner of all of the rights comprised in the
          undivided copyright (and all renewals, extensions, and reversions thereof, and all rental
          rights) in and to that Test Result in perpetuity and throughout the universe. To the
          extent that any interest in any Test Result may not be deemed a «work made for hire» under
          copyright law, we hereby irrevocably assign to the customer ordering such test Result all
          right, title and interest in any and all rights of copyright (and all renewals,
          extensions, and reversions thereof) of the Test Result (other than Our IP), subject to
          such customer’s payment of the corresponding fees for obtaining such Test Result.
        </p>
        <h2>Indemnity, Liability</h2>
        <p>
          By using our site and services each customer and tester hereby agrees to fully indemnify
          us and whomever on our behalf from and against any third-party claims relating to alleged
          infringement by such customer or tester, as applicable, of a third party’s intellectual
          property rights. By using our site and services each customer and tester hereby agrees to
          fully indemnify us and whomever on our behalf from and against any third-party claims
          relating to alleged infringement by such customer or tester, as applicable, of a third
          party’s intellectual property rights. WE EXPRESSLY DISCLAIM ANY AND ALL WARRANTIES,
          WHETHER EXPRESS OR IMPLIED, INCLUDING ALL WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
          PARTICULAR PURPOSE, TITLE, NON-INFRINGEMENT, AND ANY AND ALL WARRANTIES ARISING FROM
          COURSE OF DEALING AND USAGE OF TRADE; THAT THE SITE, THE SITE CONTENT AND OUR SERVICES
          WILL MEET YOUR REQUIREMENTS, WILL ALWAYS BE AVAILABLE, ACCESSIBLE, UNINTERRUPTED, TIMELY,
          SECURE OR OPERATE WITHOUT ERROR, AS TO THE RESULTS THAT MAY BE OBTAINED FROM THE
          OPERATION, USE OR OTHER EXPLOITATION OF THE SITE, THE SITE CONTENT AND OUR SERVICES, AND
          AS TO THE ACCURACY OR RELIABILITY OF ANY INFORMATION OBTAINED FROM THERFROM NO ADVICE OR
          INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM US OR THROUGH THE SITE WILL
          CREATE ANY WARRANTY NOT EXPRESSLY STATED HEREIN. WE PROVIDES THE SERVICES AND TESTING
          RESULTS SOLELY FOR INFORMATIONAL PURPOSES TO YOU. YOU ARE SOLELY RESPONSIBLE FOR ANY
          DECISIONS MADE BASED ON SUCH INFORMATION. YOU’RE USE OF THE SITE, THE SITE CONTENT AND THE
          SERVICES IS AT YOUR OWN RISK. TO THE FULLEST EXTENT POSSIBLE UNDER APPLICABLE LAW, NEITHER
          WE NOR ANYONE ON OUR BEHALF WILL BE LIABLE FOR ANY DAMAGES OF ANY KIND ARISING FROM OR
          RELATING TO ANY OF THEIR OPERATION, USE OR OTHER EXPLOITATION. UNDER NO CIRCUMSTANCES WILL
          YOU BE ENTITLED TO RECOVER FROM US OR ANYONE ON OUR BEHALF ANY INCIDENTAL, CONSEQUENTIAL,
          INDIRECT, PUNITIVE OR SPECIAL DAMAGES (INCLUDING DAMAGES FOR LOSS OF BUSINESS, LOSS OF
          PROFITS OR LOSS OF USE), WHETHER BASED ON CONTRACT, TORT (INCLUDING NEGLIGENCE), OR
          OTHERWISE ARISING FROM OR RELATING TO THE SITE, SITE CONTENT OR OUR SERVICES, EVEN IF HAVE
          BEEN INFORMED OR SHOULD HAVE KNOWN OF THE POSSIBILITY OF SUCH DAMAGES. OUR AGGREGATE
          LIABILITY FOR DIRECT DAMAGES TO YOU UNDER THESE TERMS SHALL BE LIMITED TO THE
          CONSIDERATION PAID OR PAYABLE BY YOU TO US FOR THE USE OF OUR SITE OR SERVICES. WITHOUT
          LIMITING THE GENERALITY OF THE FOREGOING, WE SHALL HAVE NO LIABILITY FOR ANY DAMAGES THAT
          MAY RESULT FROM YOUR USE OR INTERPRETATION OF THE SITE, THE SERVICES OR THE TEST RESULTS
          AND/OR THE ACTS OR OMISSIONS OF ANY CUSTOMER OR TESTER.
        </p>
        <h2>Miscellaneous</h2>
        <p>
          Entire Agreement. The terms and provisions herein and any Orders constitute the entire
          agreement between the parties and shall supersede all previous communications, either oral
          or written between the parties hereto with respect to the subject matter hereof. Changes.
          We reserve the right to change or discontinue the site and our services or any of them at
          any time without notice and to make changes to these terms as notified by us from time to
          time. Use of our site and services after notice has been provided will be deemed as your
          acceptance of such changes. No Waiver. No delay or omission to exercise any right, power,
          or remedy accruing to any party upon any breach or default under these Terms, shall be
          deemed a waiver of any other breach or default therefore or thereafter occurring. Any
          waiver, permit, consent, or approval of any kind or character on the part of any party of
          any breach or default under these Terms, or any waiver on the part of any party of any
          provisions or conditions of these Terms, must be in writing and shall be effective only to
          the extent specifically set forth in such writing. Severability. If any term, provision,
          covenant or restriction of these Terms is held by a court of competent jurisdiction or
          other authority to be invalid, void, unenforceable or against its regulatory or public
          policy the remainder of the terms, provisions covenants and restrictions of these Terms
          shall remain in full force and effect and shall in no way be affected, impaired or
          invalidated, it is hereby stipulated and declared to be the intention of the parties
          hereto that they would have executed the remaining terms provisions covenants and
          restrictions without including any of such which may be hereafter declared invalid, void
          or unenforceable. Governing Law. These Terms shall be exclusively governed by and
          interpreted in accordance with the laws of the State of Israel, without giving effect to
          the rules regarding conflict of law. The competent courts in the district of Tel Aviv
          Jaffa shall have sole and exclusive jurisdiction over any dispute between the parties
          hereunder. The foregoing shall not limit the ability of a party hereto to petition for
          injunctive relief in any relevant jurisdiction. No assignment. You may not assign,
          transfer, mortgage or otherwise dispose of any of its rights and/or obligations hereunder,
          in whole or in part, except with our prior written consent. Any assignment without our
          prior written consent shall be void. We may assign our obligations and rights under these
          Terms, in whole or in part, to any affiliate or as a result of any merger, sale or other
          reorganization. No Agency. The parties hereto are independent contractors and neither is
          the principal or agent of the other. Neither party shall make any promises,
          representations or warranties on behalf of the other party except as expressly provided
          for in these Terms or otherwise authorized in writing. Survival. Any provision of these
          Terms designed or intended to survive the expiration or termination of these Terms, shall
          so survive. Force Majeure. We will not be responsible for any failure to perform due to
          causes beyond our reasonable control, including, but not limited to, acts of God, war,
          acts of terrorism, riot, failure of electrical, Internet, co-location or
          telecommunications service, acts of civil or military authorities, fire, floods,
          earthquakes, accidents, strikes, or fuel crises. Publicity. You hereby agree that we may
          use your company’s / organization’s name and logo on our client lists both on our site and
          on marketing presentations. Notices. By registering to our site you understand that we may
          send you communications or data regarding the site and our services, including, but not
          limited to, notices about your use of the site or services, updates and promotional
          information and materials regarding our products and services. We may provide you with
          such information and notices by email at the address you specified when you registered.
          Notices emailed to you will be deemed given and received when the email is sent. If you
          don’t consent to receive notices electronically, you must stop using our site and
          services. All notices to us should be sent to{' '}
          <a href="mailto:info@we-test.com">info@we-test.com</a>.
        </p>
      </div>
    </div>
  );
};

export default Terms;
