export function prepareTester(tester) {
  tester.rating = parseFloat(tester.rating);

  if (typeof tester.desktopTypes === 'string') {
    // FIXME this is a temporary hack
    tester.desktopTypes = JSON.parse(tester.desktopTypes);
  }
  tester.sendInvitations = !!tester.sendInvitations;

  return tester;
}

export function prepareCustomer(customer) {
  customer.favoriteTesters = customer.favoriteTesters.map((tester) => {
    // TODO should be separate
    return {
      ...tester,
      devices: JSON.parse(tester.devices),
      browsers: JSON.parse(tester.browsers),
    };
  });

  return customer;
}
