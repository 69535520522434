import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Modal, Form, Message, Radio, Input, Icon, Button, Divider } from 'semantic-ui-react';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { UserRole } from '../types/UserRole';
import { OperationStatus } from '../types/OperationStatus';
import actions from '../redux/actions';
import logo from '../public/images/logo-color.png';
import config from '../config';

const mapStateToProps = (storeState) => {
  return {
    userRole: storeState.user.role,
    accountType: storeState.user.accountType,
    registerStatus: storeState.registerStatus.status,
    registerErrorMessage: storeState.registerStatus.errorMessage,
    modalOpen: storeState.registerModal.open,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    register: (role, email, password) => dispatch(actions.register(role, email, password)),
    registerFacebook: (role, email, accessToken, facebookbUserId) =>
      dispatch(actions.registerFacebook(role, email, accessToken, facebookbUserId)),
    open: () => dispatch(actions.registerModalOpen()),
    close: () => dispatch(actions.registerModalClose()),
    loginModalOpen: () => dispatch(actions.loginModalOpen()),
  };
};

const initialState = {
  email: '',
  password: '',
  passwordConfirm: '',
  passwordVisible: false,
  passwordConfirmVisible: false,
  role: '',
  errorMessage: '',
};

class RegisterModal extends Component<any> {
  public state: any;

  constructor(props) {
    super(props);

    this.state = initialState;
  }

  onPasswordVisibleToggle = (event, data) => {
    event.preventDefault();

    if (data['data-type'] === 'password') {
      this.setState({ passwordVisible: !this.state.passwordVisible });
    } else {
      this.setState({ passwordConfirmVisible: !this.state.passwordConfirmVisible });
    }
  };

  componentDidUpdate(prevProps) {
    if (this.props.registerStatus !== prevProps.registerStatus) {
      if (this.props.registerStatus === OperationStatus.success) {
        this.setState(
          {
            errorMessage: '',
          },
          () => {
            this.props.close();

            if (this.props.accountType === 'facebook') {
              this.props.history.push('/registerconfirm?accountType=facebook');
            } else {
              this.props.history.push(`/registerconfirm?email=${this.state.email}`);
            }
          }
        );
      } else {
        this.setState({
          errorMessage: this.props.registerErrorMessage,
        });
      }
    }
  }

  onInput = (event, data) => {
    this.setState({
      ...this.state,
      [data.name]: data.value,
    });
  };

  onFacebookEmptyRole = (event) => {
    event.preventDefault();

    return this.setState({
      errorMessage: 'Please choose your role - tester or customer',
    });
  };

  onFacebookRegisterResponse = (response) => {
    // TODO this is a dumb copypaste form LoginModal. Not even sure I need it
    if (this.props.userRole !== UserRole.Anonymous) {
      return;
    }

    if (!response.email || !response.accessToken || !response.userID) {
      return this.setState({
        errorMessage: 'An error occured during the registration',
      });
    }

    this.props.registerFacebook(
      this.state.role,
      response.email,
      response.accessToken,
      response.userID
    );
  };

  onSubmit = () => {
    if (!this.state.role.length) {
      return this.setState({
        errorMessage: 'Please choose your role - tester or customer',
      });
    }

    if (!this.state.email.length) {
      return this.setState({
        errorMessage: 'Email field is mandatory',
      });
    }

    if (this.state.password.length < 8) {
      return this.setState({
        errorMessage: 'Password need to be at least 8 characters',
      });
    }

    if (this.state.password !== this.state.passwordConfirm) {
      return this.setState({
        errorMessage: "Passwords don't match",
      });
    }

    this.setState(
      {
        errorMessage: '',
      },
      () => {
        this.props.register(
          this.state.role,
          this.state.email,
          this.state.password,
          this.state.passwordConfirm
        );
      }
    );
  };

  onLoginClick = () => {
    this.props.close();
    this.props.loginModalOpen();
  };

  onClose = () => {
    this.setState(initialState);
    this.props.close();
  };

  render() {
    return (
      <Modal
        open={this.props.modalOpen}
        onClose={this.onClose}
        className="register-modal"
        size="tiny"
        closeIcon
        trigger={<a onClick={this.props.open}>New account</a>}
      >
        <Modal.Header className="register-modal-header">
          Welcome to <img src={logo} alt="logo" />
        </Modal.Header>
        <Modal.Content>
          {this.state.errorMessage && (
            <Message negative>
              <Icon name="exclamation circle" />
              {this.state.errorMessage}
            </Message>
          )}

          <Form>
            <Form.Field className="account-type">
              Sign up as
              <Radio
                name="role"
                value={UserRole.Tester}
                label="Tester"
                onChange={this.onInput}
                checked={this.state.role === UserRole.Tester}
                required
              />
              <Radio
                name="role"
                value={UserRole.Customer}
                label="Customer"
                onChange={this.onInput}
                checked={this.state.role === UserRole.Customer}
                required
              />
            </Form.Field>
            
            <FacebookLogin
              disableMobileRedirect={true}
              appId={config.facebookAppId}
              autoLoad={false}
              isMobile={false}
              callback={this.onFacebookRegisterResponse}
              scope="public_profile, email"
              fields="name, email, picture"
              render={(renderProps) => (
                <Button
                  icon
                  type="button"
                  color="facebook"
                  className="facebook-button"
                  disabled={this.props.registerStatus === OperationStatus.processing}
                  loading={this.props.registerStatus === OperationStatus.processing}
                  onClick={this.state.role.length ? renderProps.onClick : this.onFacebookEmptyRole}
                >
                  <Icon name="facebook f" /> Register with Facebook
                </Button>
              )}
            />
            
            <Divider horizontal>Or</Divider>
            

            <Form.Field>
              <Input
                icon="envelope"
                iconPosition="left"
                type="email"
                placeholder="Email"
                name="email"
                onChange={this.onInput}
                required
              />
            </Form.Field>
            <Form.Field>
              <Input
                icon="key"
                iconPosition="left"
                type={this.state.passwordVisible ? 'text' : 'password'}
                placeholder="Password"
                name="password"
                onChange={this.onInput}
                required
                action={
                  <Button
                    onClick={this.onPasswordVisibleToggle}
                    data-type="password"
                    type="button"
                    tabIndex="-1"
                    icon
                  >
                    <Icon name={this.state.passwordVisible ? 'eye slash' : 'eye'} />
                  </Button>
                }
              />
            </Form.Field>
            <Form.Field>
              <Input
                icon="key"
                iconPosition="left"
                type={this.state.passwordConfirmVisible ? 'text' : 'password'}
                placeholder="Confirm password"
                name="passwordConfirm"
                onChange={this.onInput}
                required
                action={
                  <Button
                    onClick={this.onPasswordVisibleToggle}
                    data-type="passwordConfirm"
                    type="button"
                    tabIndex="-1"
                    icon
                  >
                    <Icon name={this.state.passwordConfirmVisible ? 'eye slash' : 'eye'} />
                  </Button>
                }
              />
            </Form.Field>
            <span className="modal-link">
              By signing up I agree to the{' '}
              <a target="_blank" href="/terms">
                Terms of service
              </a>
            </span>
            <Button
              primary
              size="large"
              type="submit"
              className="submit-button"
              disabled={this.props.registerStatus === OperationStatus.processing}
              loading={this.props.registerStatus === OperationStatus.processing}
              onClick={this.onSubmit}
            >
              Join us
            </Button>
            <span className="modal-link login-link">
              Click here to <a onClick={this.onLoginClick}>log in</a>
            </span>
          </Form>
        </Modal.Content>
      </Modal>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RegisterModal));
