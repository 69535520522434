import React, { Component } from 'react';
import { Button, Form, Input, TextArea, Message, Icon } from 'semantic-ui-react';
import actions from './../redux/actions';
import { connect } from 'react-redux';
import { OperationStatus } from '../types/OperationStatus';

const mandatoryFields = ['subject', 'firstName', 'lastName', 'email', 'message'];

const mapStateToProps = (state) => {
  return {
    contactFormStatus: state.contactFormStatus,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    sendContactFormMessage: (data) => dispatch(actions.sendContactFormMessage(data)),
  };
};

class Contact extends Component<any, any> {
  public state: any;

  constructor(props) {
    super(props);

    this.state = {
      subject: '',
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      message: '',
      errors: new Set(),
    };
  }

  componentDidUpdate(prevProps) {
    // if (prevProps.contactFormStatus.errorMessage !== this.props.contactFormStatus.errorMessage) {
    //     this.setState({
    //         contactFormErrorMessage: this.props.contactFormStatus.errorMessage
    //     })
    // }

    if (prevProps.contactFormStatus.status !== this.props.contactFormStatus.status) {
      if (this.props.contactFormStatus.status === OperationStatus.success) {
        this.setState({
          subject: '',
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          message: '',
          contactFormErrorMessage: '',
        });
      }
    }
  }

  onChange = (event, data) => {
    this.setState({
      [data.name]: data.value,
    });
  };

  onSubmit = (event, data) => {
    const errors = this.state.errors;

    const payload = {
      subject: this.state.subject,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      email: this.state.email,
      phone: this.state.phone,
      message: this.state.message,
    };

    for (const el of mandatoryFields) {
      if (!payload[el]) {
        errors.add(el);
      }
    }

    if (errors.size) {
      return this.setState({ errors });
    }

    this.props.sendContactFormMessage(payload);
  };

  resetErrorState = (event) => {
    if (this.props.contactFormStatus.errorMessage.length) {
      this.setState({
        contactFormErrorMessage: '',
      });
    }
    const name = event.target.name;
    const errors = new Set(this.state.errors);

    if (errors.has(name)) {
      errors.delete(name);
      this.setState({ errors });
    }
  };

  validateInput = (event, data) => {
    const errors = new Set(this.state.errors);
    const name = data && data.name ? data.name : event.target.name;
    const rawValue = data && data.value ? data.value : event.target.value;

    const value = `${rawValue}`.trim();

    if (!value) {
      if (name !== 'phone') {
        errors.add(name);
      }
    }

    this.setState({ errors });
  };

  render() {
    const errors = this.state.errors;

    return (
      <div className="contact page">
        <div className="heading">
          <h1>Contact Us</h1>
        </div>
        <div className="inner">
          <h2>Have any questions or suggestions? Contact us</h2>
          <Form className="contact-form" onSubmit={this.onSubmit}>
            <label>Subject</label>
            <Input
              name="subject"
              value={this.state.subject}
              onChange={this.onChange}
              onFocus={this.resetErrorState}
              error={errors.has('subject')}
              required
            />
            <label>First Name</label>
            <Input
              name="firstName"
              value={this.state.firstName}
              onChange={this.onChange}
              onFocus={this.resetErrorState}
              error={errors.has('firstName')}
              required
            />
            <label>Last Name</label>
            <Input
              name="lastName"
              value={this.state.lastName}
              onChange={this.onChange}
              onFocus={this.resetErrorState}
              error={errors.has('lastName')}
              required
            />
            <label>Email</label>
            <Input
              type="email"
              name="email"
              value={this.state.email}
              onChange={this.onChange}
              onFocus={this.resetErrorState}
              error={errors.has('email')}
              required
            />
            <label>Phone</label>
            <Input name="phone" value={this.state.phone} onChange={this.onChange} />
            <label>Message</label>
            <TextArea
              name="message"
              value={this.state.message}
              onChange={this.onChange}
              onFocus={this.resetErrorState}
              className={errors.has('message') ? 'error' : ''}
              required
            />

            {this.state.errors.size > 0 && (
              <Message negative>
                <Icon name="exclamation circle" />
                Please fill all mandatory fields
              </Message>
            )}

            <Button
              type="submit"
              className="send-button"
              positive
              size="huge"
              loading={this.props.contactFormStatus.status === OperationStatus.processing}
              disabled={this.props.contactFormStatus.status === OperationStatus.processing}
            >
              Send
            </Button>
          </Form>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Contact);
