import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';

class Footer extends Component<any> {
  render() {
    return (
      <footer>
        <div className="inner">
          <p>&copy; 2022 We-Test.com</p>
          <div className="footer-social">
            <p>Find us on social networks: crowd testing</p>
            <ul>
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://t.me/joinchat/AAAAAFAfHH7vZHNJGc7wDA"
                >
                  <Icon name="telegram" />
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.linkedin.com/company/we-test?trk=nav_account_sub_nav_company_admin"
                >
                  <Icon name="linkedin" />
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://twitter.com/WeTestonline"
                >
                  <Icon name="twitter" />
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.facebook.com/WeTest.online"
                >
                  <Icon name="facebook" />
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="http://we-test-marketplace.blogspot.co.il/"
                >
                  <Icon name="blogger" />
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.pinterest.com/wetest4u/"
                >
                  <Icon name="pinterest" />
                </a>
              </li>
            </ul>
          </div>
          <ul className="footer-links">
            <li>
              <Link to="/terms">Terms and Conditions</Link>
            </li>
            <li>
              <Link to="/privacy">Privacy Policy</Link>
            </li>
            <li>
              <Link to="/articles">Articles</Link>
            </li>
            <li>
              <Link to="/about">About Us</Link>
            </li>
            <li>
              <Link to="/contact">Contact Us</Link>
            </li>
            <li>
              {' '}
              <a href="skype:we-test?call">
                <Icon size="large" name="skype" /> Customer Care{' '}
              </a>
            </li>
          </ul>
        </div>
      </footer>
    );
  }
}

export default Footer;
