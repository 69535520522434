import React from 'react';
import { Modal, Form, Input, Icon, Button, Message } from 'semantic-ui-react';
import { OperationStatus } from '../types/OperationStatus';

const PasswordResetModal = (props) => {
  return (
    <Modal
      open={props.open}
      onClose={props.onClose}
      size="tiny"
      trigger={<a onClick={props.onOpen}>Forgot your password?</a>}
      closeIcon
    >
      <Modal.Header>Password Recovery</Modal.Header>
      <Modal.Content>
        {props.errorMessage && (
          <Message negative>
            <Icon name="exclamation circle" />
            {props.errorMessage}
          </Message>
        )}

        {props.status === OperationStatus.success ? (
          <Message positive>
            <Message.Header>Success!</Message.Header>
            Please check your email for the password recovery instructuions
          </Message>
        ) : (
          <Form>
            <label>Please enter your email</label>
            <Form.Field className="account-type">
              <Input
                icon="envelope"
                iconPosition="left"
                type="email"
                placeholder="Email"
                name="email"
                onChange={props.onInput}
                required
              />
            </Form.Field>

            <Button
              primary
              size="large"
              type="submit"
              className="submit-button"
              disabled={props.status === OperationStatus.processing}
              loading={props.status === OperationStatus.processing}
              onClick={props.onSubmit}
            >
              Recover password
            </Button>
          </Form>
        )}
      </Modal.Content>
    </Modal>
  );
};

export default PasswordResetModal;
