import React from 'react';
import { Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';

import playtikaLogo from '../public/images/playtika.png';
import corticaLogo from '../public/images/cortica.png';
import matrixLogo from '../public/images/matrix.png';
import byxLogo from '../public/images/byx.png';
import chosenfmLogo from '../public/images/chosen.fm.png';
import dvtelLogo from '../public/images/dvtel.png';
import keeprzLogo from '../public/images/keeprz.png';
import mapaLogo from '../public/images/mapa.png';
import mysizeidLogo from '../public/images/mysizeid.png';
import holdingsLogo from '../public/images/888holdings.gif';
import WatchVideoModal from './WatchVideoModal';

const Home = () => {
  const sliderSettings = {
    arrows: false,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className="home page">
      <div className="heading">
        <h1>Crowd Testing Service Online</h1>
        <span className="introtext">
          Your users live, work and play in the real world, test your application here and get
          immediate results from all of them at once.
        </span>
        <div className="intro-buttons">
          {/* <WatchVideoModal /> */}
          <a href='/watchVideo'>
            <Icon name="video play" /> Watch video
          </a>
          <Link to="/samplereports">
            <Icon name="file alternate outline" /> Sample reports
          </Link>
        </div>

        <Link to="/contact" className="start-here">
          Launch your project
        </Link>
      </div>

      <div className="partners">
        <h2>Some of Our Business Partners and Customers</h2>
        <div className="logos">
          <img src={matrixLogo} alt="" />
          <img src={playtikaLogo} alt="" />
          <img src={dvtelLogo} alt="" />
          <img src={mapaLogo} alt="" />
          <img src={holdingsLogo} alt="" />
          <img src={keeprzLogo} alt="" />
          <img src={byxLogo} alt="" />
          <img src={mysizeidLogo} alt="" />
          <img src={corticaLogo} alt="" />
          <img src={chosenfmLogo} alt="" />
        </div>
      </div>

      <div className="howitworks grey">
        <h2>How it works? 3 simple steps</h2>
        <div className="steps">
          <div className="step step1" data-num="1">
            <div className="step-text">
              <div className="step-title">Describe your product</div>
              <div className="step-description">
                Describe your product, it’s features, behavior and scope. Our step-by-step wizard
                will easily guide your through this quick process
              </div>
            </div>
          </div>
          <div className="step step2" data-num="2">
            <div className="step-text">
              <div className="step-title">Set your validation goals</div>
              <div className="step-description">
                Select your testers and specify test scope. You have full control of the number of
                testers and their location - all of our testers name their own price and are
                reviewed and rated by customers like you, based on their previous performance and
                experience.
              </div>
            </div>
          </div>
          <div className="step step3" data-num="3">
            <div className="step-text">
              <div className="step-title">Results are in!</div>
              <div className="step-description">
                Review bugs or usability reports in real-time, online. Interact with your testers as
                needed. Start improving your product within hours!
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="carousel blue">
        <div className="inner">
          <Slider {...sliderSettings} className="carousel-slider">
            <div>
              <h3>Affordable Testing</h3>
              <p>
                Our testers name their own prices! We will help you compile the test team that’s
                right for your needs, criteria, budget and priorities. With We-Test, you can afford
                quality testing{' '}
              </p>
            </div>
            <div>
              <h3>Real-Time Reports – Available within 24hrs</h3>
              <p>
                Whether it’s a mobile or web bug report, or a usability one, they both come in a
                familiar form. Reports are available within 24 hours and in real-time, online, at
                any time and from anywhere, allowing you to assess the quality and improve your
                product within hours. Speed time-to-market for your product and decrease your
                testing costs!{' '}
              </p>
            </div>
            <div>
              <h3>Access to top rated testers</h3>
              <p>
                Our testers go through an initial screening process, and all have their rating
                determined by your level of satisfaction with their work — each bug they find, each
                usability feedback they give. We will help you select the top-rated testers who meet
                your project specifications, and you will be able to freely communicate with them
                throughout the testing cycle
              </p>
            </div>
            <div>
              <h3>24/7 testing. All over the world!</h3>
              <p>
                24/7 testing, around the globe! When the sun sets for some of our testers, others
                are hard at work. We have testers all around the world, providing you with valuable
                test reports when you need it the most. 24/7, 365 days a year, We-Test will never
                stop testing for you!{' '}
              </p>
            </div>
          </Slider>
        </div>
      </div>

      <div className="testimonials inner">
        <h2>Customers Testimonials</h2>
        <div className="testimonials-item">
          <img src={mapaLogo} alt="byx-logo" />
          <div className="testimonials-content">
            <h3>Sigal Amsalem</h3>
            <span className="position">Deputy Director General, Mapa Internet</span>
            <p>
              "The system is easy, friendly, and fast... The idea of connecting a QA team available
              24x7 with our web or app goals, and getting real feedback about technical, design and
              interfaces problems is a virtue...", "...I was positively surprised by the test
              results, especially in places where I was not sure if the platform can provide
              results..."
            </p>
          </div>
        </div>

        <div className="testimonials-item">
          <img src={byxLogo} alt="byx-logo" />
          <div className="testimonials-content">
            <h3>Yigal Spielman</h3>
            <span className="position">BYX founder and CEO</span>
            <p>
              "Today I received my first test report from this ingenious system, my online defect
              report was filled in less than 24 hours from various testers, browsers and devices out
              there...corresponding with them was a pleasure and i got to learn much more about my
              product. For me ( and anyone else developing a website , application or software )
              this service is spot on! My dev team that now needs to fix are less satisfies. Thank
              you! "{' '}
            </p>
          </div>
        </div>

        <div className="testimonials-item">
          <img src={keeprzLogo} alt="byx-logo" />
          <div className="testimonials-content">
            <h3>Netta Migdali</h3>
            <span className="position">Business Operations, Keeperz</span>
            <p>
              "Using We-Test is a great experience. With fast response time, affordable price and
              great testers We-Test opens a new world of QA testing and allows us to maximize the
              quality of our product before released to market"
            </p>
          </div>
        </div>
      </div>

      <div className="description grey">
        <div className="inner">
          <p>
            We-Test crowd testing services helps you improve the quality of your website or
            application faster, better, more effective and at the best price compared to any other
            testing service available today. Our unique technology will help you find the best, and
            most suitable testers to validate your product at the best budget that best fits your
            needs.
          </p>
          <p>
            We-Test works with whichever device or platform you prefer to use: desktop, mobile or
            tablet. Just provide a short description of your product, your testing focus such as
            finding bugs, usability feedback, etc., and let We-Test do the rest.
          </p>
          <p>
            We-Test can also help you estimate the star rating of your application, before you even
            upload it and make it available to the general public, avoiding embarrassing typos, or
            other quality issues.{' '}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Home;
