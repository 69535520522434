import { OperationStatus } from '../../types/OperationStatus';

export default (state = null, action) => {
  switch (action.type) {
    case 'REGISTER_START':
      return {
        status: OperationStatus.processing,
        errorMessage: '',
      };
    case 'REGISTER_SUCCESS':
      return {
        status: OperationStatus.success,
        errorMessage: '',
      };
    case 'REGISTER_FAIL': {
      const errorMessage = Array.isArray(action.errorMessage)
        ? action.errorMessage.join(', ')
        : action.errorMessage;

      return {
        status: OperationStatus.fail,
        errorMessage,
      };
    }
    default:
      return {
        status: OperationStatus.notStarted,
        errorMessage: '',
      };
  }
};
