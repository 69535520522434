import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Menu } from 'semantic-ui-react';
import LoginModal from './LoginModal';
import RegisterModal from './RegisterModal';
import { UserRole } from '../types/UserRole';
import actions from '../redux/actions';
import logo from '../public/images/logo.png';

const mapStateToProps = (state) => {
  return {
    role: state.user.role,
    loginStatus: state.loginStatus.status,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleSidebar: () => dispatch(actions.toggleSidebar()),
    logout: () => dispatch(actions.logout()),
  };
};

class Header extends Component<any> {
  onLogout = () => {
    this.props.logout();
  };

  render() {
    return (
      <header>
        <div className="inner">
          <Link className="logo" to="/">
            <img src={logo} alt="logo" />
          </Link>

          {this.props.role !== UserRole.Anonymous && (
            <Menu text as="nav">
              <Menu.Item>
                <Link to="/account">My account</Link>
              </Menu.Item>
              <Menu.Item>
                <a onClick={this.onLogout}>Logout </a>
              </Menu.Item>
            </Menu>
          )}

          {this.props.role === UserRole.Anonymous && (
            <Menu text as="nav">
              <Menu.Item>
                <LoginModal />
              </Menu.Item>
              <Menu.Item>
                <RegisterModal />
              </Menu.Item>
              <Menu.Item>
                <Link to="/becomeatester" className="becomeatester">
                  Become a tester
                </Link>
              </Menu.Item>
            </Menu>
          )}
        </div>
      </header>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
